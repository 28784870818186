
import Vue from 'vue';
import Component from "vue-class-component";
import { Prop } from 'vue-property-decorator';

import { CompanyDashboardApi } from '../companyDashboardAPI/CompanyDashboardAPI';

import LoadingSpinner from "@/components/LoadingSpinner.vue"
import MainCard from "@/components/MainCard.vue"
import DialogDivider from '../components/DialogDivider.vue';
import { OrderingRule } from '@/companyDashboardAPI/OrderingRule';
import OrderingRuleForm from '@/components/OrderingRuleForm.vue';
import { Branch } from '@/companyDashboardAPI/Branch';

@Component({
  components: {
    MainCard: MainCard,
    LoadingSpinner: LoadingSpinner,
    DialogDivider: DialogDivider,
    OrderingRuleForm,
  }
})
export default class DeliveryRuleEditView extends Vue {
  @Prop({ required: true })
  public readonly ApiClient!: CompanyDashboardApi;

  public loadingText = this.$vuetify.lang.t(`$vuetify.loadingPleaseWait`);
  public errorMessage = '';

  public orderingRuleData: OrderingRule | null = null;

  public branches: Branch[] = [];

  /**
   * Called on mounted.
   *
   * Load all information for the form.
   */
  private async mounted() {
    const orderingRuleParam = JSON.parse(this.$route.params.orderingRuleData);

    // Load ordering rule data.
    this.loadingText = this.$vuetify.lang.t(`$vuetify.loadingPleaseWait`);
    for await (const orderingRulesData of this.ApiClient.getOrderingRules()) {
      orderingRulesData.forEach(rule => {
        if (rule.RuleId == orderingRuleParam.RuleId) {
          this.orderingRuleData = JSON.parse(JSON.stringify(rule));
        }
      })
    }

    await this.initBranches();

    // Stop loading spinner and show the form.
    this.loadingText = '';
  }

  private async initBranches(): Promise<void> {
    for await (const branchData of this.ApiClient.getBranches()) {
      this.branches = branchData;
    }
  }

  public cardTitle(): string {
    return this.$vuetify.lang.t(`$vuetify.orderingRulesTitle`);
  }

  public ruleSaved(): void {
    this.$router.push({
      name: 'OrderingRules'
    });
  }
}
